import {Analytics, getAnalytics, logEvent} from "firebase/analytics";

import {initializeApp} from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyAwM_C7qVfgkaQa7yTd5x9aUTvbp5IXPiI",
    authDomain: "collage-maker-fc-app.firebaseapp.com",
    projectId: "collage-maker-fc-app",
    storageBucket: "collage-maker-fc-app.appspot.com",
    messagingSenderId: "593884171904",
    appId: "1:593884171904:web:047f3490fcb1ba94bc2065",
    measurementId: "G-JRS8N0E4MQ"
  };
  export let firebaseAnalytics: Analytics;

  export  function logFirebaseEvent(eventName: string, eventParams?: {
    [key: string]: any;
}) {
    logEvent(firebaseAnalytics,eventName, eventParams);
  }
  export async function initFirebaseAnalytics(): Promise<
      Analytics
    > {
      
      
      const app = initializeApp(firebaseConfig);
      // const analytics = getAnalytics(app);
      if (!firebaseAnalytics) {
  
        firebaseAnalytics =  getAnalytics(app);
      }
      return firebaseAnalytics;
    }