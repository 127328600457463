'use client'

import {logEvent} from 'firebase/analytics';
import {createContext, ReactNode, useEffect} from 'react';
import {firebaseAnalytics, initFirebaseAnalytics} from '../helpers/firebase_helper';
import {useRouter} from 'next/router';

export const FirebaseContext = createContext(firebaseAnalytics);

export const FirebaseTrackingProvider = (props: { children: ReactNode }) => {
  const router = useRouter();
//   const [tracking, setTrackin`g] = useState<Analytics | null>(null);
  
  useEffect(() => {

      initFirebaseAnalytics().then((v)=>{
        // setTracking(firebaseAnalytics);
        const handleRouteChange = (url: string) => {
            console.log("page_view " + url)
            if (!firebaseAnalytics) {
              return;
            }
      
            console.log("page_view " + url)
            logEvent(firebaseAnalytics,'page_view', {
              page_location: url,
              page_title: document?.title,
            });
            // TagManager.dataLayer({
            //     dataLayer:{
            //         event: "page_view",
            //         page_location: url,
            //         page_title: document?.title,
            //     },
        
            //     })

            router.events.on('routeChangeStart', handleRouteChange);

            return () => {
              router.events.off('routeChangeStart', handleRouteChange);
            };
          };
      });





  }, [firebaseAnalytics]);

  return (
    <FirebaseContext.Provider value={firebaseAnalytics}>
      {props.children}
    </FirebaseContext.Provider>
  );
};