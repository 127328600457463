import "../../styles/globals.css";

import * as Sentry from "@sentry/react";

import type {AppProps} from "next/app";
import {BrowserTracing} from "@sentry/tracing";
import {FirebaseTrackingProvider} from "../components/FirebaseTrackingProvider";
// import TagManager from "react-gtm-module";
import Head from "next/head";
import {ReCaptchaProvider} from "next-recaptcha-v3";
import {TailwindIndicator} from "../components/TailwindIndicator";

const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
Sentry.init({
    dsn: "https://aa4523bfb0094bb0b550ccebd7fc1435@o1256900.ingest.sentry.io/6426357",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0125,
});

function MyApp({Component, pageProps}: AppProps) {

    return (
      <ReCaptchaProvider reCaptchaKey={NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
        <FirebaseTrackingProvider>
          <>
            <Head>
              <title>
                Free Online Collage Maker & Photo Combiner - PhotoJoiner
              </title>
              <meta
                name="description"
                content="Create a beautiful collage or stitch photos together. Use thousands of creative assets to bring your creations to life."
              />

              <script
                src="/appsflyer.js"
                type="text/javascript"
                async
                id="userreport-launcher-script"
              ></script>
            </Head>
            <div id="modal  _portal_container"></div>
            <Component {...pageProps} />
            <TailwindIndicator />
          </>
        </FirebaseTrackingProvider>
      </ReCaptchaProvider>
    );
}

export default MyApp;
